import React from "react";
import { Link } from "gatsby";
import "../styles/header.scss";
import "../styles/global_styles.scss";
import PhoneNav from "./PhoneNav";

export default function Header() {
  return (
    <header className="main__header">
      <div className="header__wrapper">
        <Link to="/">
          <div className="logo">Ljungs Måleri & Byggnadsvård AB</div>
        </Link>
        <ul className="desktop__menu">
          <li>
            <Link to="/" activeStyle={{ color: "#dd7640" }}>
              Hem
            </Link>
          </li>
          <li>
            <Link to="/gallery" activeStyle={{ color: "#dd7640" }}>
              Galleri{" "}
            </Link>
          </li>
          <li>
            <Link to="/om_oss" activeStyle={{ color: "#dd7640" }}>
              Om oss
            </Link>
          </li>
        </ul>
        <PhoneNav></PhoneNav>
      </div>
    </header>
  );
}
