import React from "react";
import { Link } from "gatsby";
import "../styles/phoneNav.scss";

export default function PhoneNav() {
  const togglePhoneMenu = () => {
    const menu = document.querySelector(".phone__menu__wrapper");
    const hamburgerButton = document.querySelector(".hamburger__button");
    menu.classList.toggle("active__phone__menu");
    hamburgerButton.classList.toggle("active__hamburger__button");
  };

  return (
    <>
      <button
        onClick={togglePhoneMenu}
        className="hamburger__button__container"
      >
        <div className="hamburger__button"></div>
      </button>
      <div className="phone__menu__wrapper">
        <ul className="phone__menu__list">
          <li>
            <Link to="/" activeStyle={{ color: "#dd7640" }}>
              Hem
            </Link>
          </li>
          <li>
            <Link to="/gallery" activeStyle={{ color: "#dd7640" }}>
              Galleri{" "}
            </Link>
          </li>
          <li>
            <Link to="/om_oss" activeStyle={{ color: "#dd7640" }}>
              Om oss
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}
